.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.css-81pzxm {
  display: none !important;
}
.input-main-box .MuiStack-root {
  background-color: transparent !important;
}
.reverse {
  flex-direction: row-reverse !important;
}
.user-input-reverse .MuiInputBase-formControl {
  flex-direction: row-reverse !important;
}
.user-input-reverse .MuiInputBase-formControl input {
  text-align: end !important;
}
.user-input-reverse .MuiInputBase-formControl .MuiInputAdornment-root {
  rotate: 180deg !important;
}
.user-input input {
  color: #fff !important;
}
.mic-icon:hover {
  background-color: azure !important;
}
.mic-icon-avatar {
  position: fixed !important;
  right: 10px;
  bottom: 10px;
}
.css-dl0i46 {
  min-height: 100vh;
}
.bg-black {
  background-color: #000 !important;
}

/* @media only screen and (max-width: 600px) {
  canvas {
    /* padding-bottom: 15px; */
/* margin-top: -160px;
  }
} */
@media (min-width: 0px) and (max-width: 599.95px) {
  .css-1dwp7di {
    height: 100vh !important;
    border-radius: 0;
    padding-bottom: 0px !important;
  }
  .css-1u49z21 {
    padding-bottom: 0 !important;
  }
}

/* Modal styles */
.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  backdrop-filter: blur(15px);
}
.modal img {
  border-radius: 10px;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}
/* Modal content */
.modal-content {
  background-color: #ffffff40;
  margin: 10% auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 400px;
  text-align: center;
}

/* Button styles */
#openModalBtn,
#modalButton {
  background-color: #757575;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#openModalBtn:hover,
#modalButton:hover {
  background-color: #333;
}
.modal-text {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.audio-input {
  position: absolute;
  bottom: 10px;
  left: 50px !important;
  background-color: #282c34;
  color: white;
  font-size: 24px;
  padding: 5px 10px;
  height: 50px;
}
@media screen and (max-width: 768px) {
  .audio-input {
    top: 10px !important;
    right: 10px !important;
  }
}

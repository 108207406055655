.chatField {
  display: inline-block;
  background-color: #5d22f9;
  padding: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  max-width: 50%;
}
.chatField2 {
  display: inline-block;
  background-color: #888888a6;
  /* color: black; */
  padding: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  max-width: 50%;
}
.chatBox {
  margin: 15px 0;
  width: 100%;
  display: flex;
  z-index: 1;
}
.chatBox2 {
  z-index: 1;
  margin: 23px 0;
  width: 100%;
  display: block;
  text-align: right !important;
}

/* @media screen and (max-width: 600px) {
  .chatField,
  .chatField2 {
    max-width: 100% !important;
    font-size:2px !important;
  }
} */

@media screen and (max-width: 768px) {
  .chat-d {
    width: 100%;
  }
}

.mainDiv {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: linear-gradient(to right, #a200f6 , #14256a); */
}
.loginCard {
  display: flex !important;
  flex-direction: inherit !important;
  border-radius: 50px !important;
  box-shadow: rgb(49, 49, 48) 0px 22px 70px 4px;
  min-height: 460px;
  border: none !important;
  background: white;
  max-width: 850px;
}
.loginImg {
  max-width: 250px !important;
  border-radius: 28px;
  border-top-left-radius: 28px !important;
  border-top-right-radius: 28px !important;
}
.cardBody {
  width: 250px !important;
  position: relative;
}
.welcomeTag {
  /* background-image: linear-gradient(to right, #14256a , #a200f6); */
  color: #fff;
  padding: 9px;
  top: 10px;
  margin: 0;
  display: inline-block;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  position: absolute;
  left: 0;
}
.inputFields {
  outline: none !important;
  padding: 0 !important;
  padding-bottom: 7px !important;
  border: none !important;
  border-bottom: 2px solid #7d28a9 !important;
  border-radius: 0 !important;
  margin-top: 30px !important;
}

.inputFields::-webkit-input-placeholder {
  color: #7d28a9;
}

.inputFields::-moz-placeholder {
  color: #7d28a9;
}

.inputFields:-ms-input-placeholder {
  color: #7d28a9;
}

.inputFields::placeholder {
  color: #7d28a9;
}

.loginBtn {
  padding: 8px 24px !important;
  border-radius: 25px !important;
  outline: none !important;
  margin: 10px !important;
  color: #ffffff;
  border: none !important;
}
.userPanelHeading {
  font-size: 20px;
  font-weight: 600;
  margin-top: 90px;
  color: #7d28a9;
  text-align: center;
}
.cardBodyLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px !important;
  height: 100% !important;
}
.imgDiv {
  background-color: #a200f6;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
}
.leftDivText {
  padding: 5px 34px;
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
}
.css-jeffex {
  margin-top: 0 !important;
  padding: 0 !important;
}
.noData {
  font-size: 2rem;
  color: red;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #ff000029;
}
.errorImg {
  width: 200px;
  /* margin-right: 21px; */
  border-radius: 50%;
}

@media screen and (max-width: 800px) {
  .cardBody {
    width: 200px !important;
  }
  .cardBodyLeft {
    width: 350px !important;
  }
  .loginImg {
    max-width: 184px !important;
  }
}

@media screen and (max-width: 580px) {
  .loginCard {
    flex-direction: column !important;
    border-radius: 0 !important;
  }
  .cardBodyLeft {
    width: 300px !important;
    margin-top: 25px;
  }
  .leftDivText {
    margin: 0;
  }
  .imgDiv {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .loginImg {
    max-width: 150px !important;
  }
  .imgDiv,
  .cardBody {
    width: 100% !important;
    border-radius: unset !important;
  }
  .userPanelHeading {
    margin-top: 60px !important;
  }
  .welcomeTag {
    padding: 7px;
    margin: 0;
  }
}

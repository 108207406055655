.backgroundImg {
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  /* top: 0; */
  z-index: 0;
  height: 50vh;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.backgroundVid {
  position: fixed;
  z-index: 0;
  width: 33vw;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  max-width: 100%; */
  /* position: fixed;
  z-index: 0;
  height: 100%;
  top: 50%;
  left: 50%; */
  /* -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
   */
  /* transform: translate(-50%, -50%); */
}

.css-1dwp7di {
  /* background-color: black !important; */
}
@media screen and (max-width: 768px) {
  .backgroundImg {
    height: 70vh;
  }
}

@media screen and (max-width: 1440px) {
  .backgroundVid {
    height: 90vh;
    width: 86vw;
  }
}

@media screen and (max-width: 425px) {
  .backgroundVid {
    object-fit: cover;
    height: 45vh;
    max-width: 163%;
    max-height: 143%;
    width: auto;
  }
  .backgroundImg {
    height: 100vh;
  }
  .text input::placeholder {
    font-size: 0.9rem;
  }
  .chat-show {
    text-align: start;
    text-wrap: wrap;
    background-color: #1553d1;
    border-radius: 5px;
    padding: 0 10px;
    color: white;
    font-size: 1.2rem;
    position: fixed;
    bottom: 3rem;
    left: 0rem;
    z-index: 1;
  }
}

@media screen and (max-width: 320px) {
  .text input::placeholder {
    font-size: 0.7rem;
  }
  .icon-div {
    padding-left: 5px !important;
    padding-right: 5px;
  }
}

/* <div class="text-wrap text-start input-display">NHC heard: اخبرني<div><div class="spinner-border spinner-border-sm ms-1" role="status"><span class="visually-hidden">Loading...</span></div></div></div> */
.chat-d {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.history-message-group--PLAYER li .chat-d div:nth-child(1) {
  display: none;
}

.chat-d div {
  z-index: 1;
}
.chat-show {
  text-align: start;
  text-wrap: wrap;
  word-wrap: break-word;
  border: 1px solid grey;
  /* background-color: #1553d1; */
  border-radius: 3px;
  padding: 5px 10px;
  color: lightgrey;
  font-size: 1.2rem;
  position: fixed;
  bottom: 0rem;
  left: 4rem;
  z-index: 1;
  max-width: 500px;
}

/* @media screen and (max-width: 768px) {
  .backgroundImg {
    height: 50vh;
    top: 20%;
  }
} */
.bodyContainer {
  background-repeat: repeat !important;
  /* display: flex !important; */
  /* justify-content: center !important; */
  /* align-items: end !important; */
  width: 100%;
  height: 100%;
  /* background: #06070d; */
}
.css-17babdh {
  max-width: 100% !important;
}
.css-y3v3gx-MuiSvgIcon-root {
  color: #fff !important;
}

.css-12a4258 {
  color: #fff !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: end;
  z-index: 2;
  padding-bottom: 85px !important;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f124;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.inputField {
  /* background: #260e35 !important; */
  border: 1px solid #faf8f5c2 !important;
  border-radius: 35px !important;
  color: #fff !important;
}
/* .css-1emzoha-MuiInputBase-input-MuiInput-input {
  color: #fff !important;
} */
.css-1rlitzi-MuiStack-root {
  padding: 10px;
  /* background: #260e35a1; */
  position: fixed !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 5;
}
.css-19nxypa {
  color: #fff !important;
}
.css-8v90jo {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.imgParent {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.audio {
  position: fixed;
  top: 20px;
  right: 50px;
}
.audio audio {
  color: white;
}
.right-div {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  /* min-height: 100vh; */
  margin-top: auto;
}

.header-div {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 8px;
  z-index: 1;
}

.header-logo {
  width: 45px;
}

.header-text {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  flex-grow: 1;
}

.flex {
  display: flex;
}

.icons {
  display: flex;
}
.toggle-icon {
  display: none !important;
}

.show {
  display: none;
}

.icon-wrapper {
  position: relative;
}
@media screen and (max-width: 767px) {
  .toggle-icon {
    display: flex !important;
  }
  .icons {
    flex-direction: column;
    position: absolute;
    bottom: 40px;
    right: 0px;
  }
}
@media screen and (max-width: 600px) {
  .icon-div {
    width: 115px !important;
  }
  .content-div {
    width: 100% !important;
    padding-bottom: 0 !important;
  }
  .header-text {
    display: none;
  }
  .header-logo {
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 425px) {
  .header-logo {
    font-size: 16px;
  }
}
